import { Box, Typography, Snackbar } from '@mui/material';
import LogoSection from '../LogoSection';
import Profile from './Profile';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { map } from 'lodash';
import ReviewReceiver from './ReviewReceiver';
import PBSAIAPI from 'views/apis/PBSAIAPI';
import { useSelector } from 'react-redux';
import { useWebSocket } from 'utils/useWebSocket';
import { CloseIcon } from 'ui-component/icons/icons';
import ReviewSender from './ReviewSender';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout.header.base,
}));

const headerList = [
	{
		id: 1,
		title: '워크리스트',
		path: '/',
	},
	{
		id: 2,
		title: '결과입력',
		path: `/result`,
	},
	{
		id: 3,
		title: '설정',
		path: '/setting',
	},
];

const Header = () => {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;
	const memberInfo = useSelector((state) => state.session?.memberInfo);
	const theme = useTheme();

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/
	const [_tabId, setTabId] = useState(1);
	const [_reviewReceiverList, setReviewReceiverList] = useState([]);
	const [_reviewSenderList, setReviewSenderList] = useState([]);
	// Snackbar 상태 관리
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	

	//멤버 알람(웹소켓)
	const [openAlarm, setOpenAlarm] = useState(false);
	const [currentMessage, setCurrentMessage] = useState("");
    const [loading, setLoading] = useState(true); // memberInfo 로딩 상태 추가
	const { messages } = useWebSocket(!loading ? memberInfo.memberId : null);

	

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/
	useEffect(() => {
		// 메인 페이지 경로
		const mainPagePath = '/';

		// 결과 입력 페이지 경로
		const resultPagePath = '/result';

		// 설정 페이지 경로
		const settingPagePath = '/setting';

		if (currentPath === mainPagePath) {
			// 현재 페이지가 메인 페이지 경로일 때 실행할 코드
			setTabId(1);
		} else if (currentPath.startsWith(resultPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(2);
		} else if (currentPath.startsWith(settingPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(3);
		}
	}, [currentPath]);

	// 보낸 요청 수신
	useEffect(() => {
		//초기 ID 제외
		if (memberInfo.memberId === 0) return;
		
		PBSAIAPI.GetReviewRequestSenderList(memberInfo.memberId).then((res) => {
			if (res.status === 200) {
				// 웹소켓 업데이트 시 신규 요청 검사
				const newItems = res.data.filter(
					(item) => !_reviewSenderList.some((existingItem) => existingItem.reviewRequestId === item.reviewRequestId)
				);

				if (newItems.length === 0) {
					return 
				}

				// 웹소켓 업데이트 시 신규 요청 정보만 API 호출
				const workListPromises = newItems.map((item) =>
					PBSAIAPI.GetWorkList(item.workListId).then((response) => {
						if (response.status === 200) {
							return {
								infoWorkList:response.data,
								...item,
							};
						}
						return null;
					})
				);

				Promise.all(workListPromises).then((responses) => {
					const validWorkLists = responses.filter((response) => response !== null);
					setReviewSenderList((currentList) => [...currentList, ...validWorkLists]);
				});
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberInfo.memberId,messages])

	// 받은 요청 수신/갱신
	useEffect(() => {
		//초기 ID 제외
		if (memberInfo.memberId === 0) return;

		const fetchReviewRequestReceiverList = async () => {
			const res = await PBSAIAPI.GetReviewRequestReceiverList(memberInfo.memberId);
			if (res.status !== 200) return;

			// 웹소켓 업데이트 시 신규 요청 검사
			const newItems = res.data.filter(
				(item) => !_reviewReceiverList.some((existingItem) => existingItem.reviewRequestId === item.reviewRequestId)
			);

			if (newItems.length === 0) return;
			// 웹소켓 업데이트 시 신규 요청 정보만 API 호출
			const workListPromises = newItems.map((item) =>
				PBSAIAPI.GetWorkList(item.workListId).then((response) => {
					if (response.status === 200) {
						return {
							infoWorkList:response.data,
							...item,
						};
					}
					return null;
				})
			);

			const responses = await Promise.all(workListPromises);
			const validWorkLists = responses.filter((response) => response !== null);

			if (validWorkLists.length > 0) {
				// 기존 리스트에 신규 유효 항목 추가
				setReviewReceiverList((currentList) => [...currentList, ...validWorkLists]);
			}
		};

		fetchReviewRequestReceiverList();

	//멤버정보 로딩 후 & 메시지 들어올 떄 받은요청 데이터 갱신
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberInfo.memberId,messages]);

	// 웹소켓1
    // memberInfo가 로딩 완료된 경우에만 WebSocket을 설정
    useEffect(() => {
        if (memberInfo && memberInfo.memberId) {
            setLoading(false); // memberInfo가 로딩 완료되면 loading 상태를 false로 변경
        }
	}, [memberInfo]);
	

	// 웹소켓2
	useEffect(() => {
        if (messages.length > 0) {
            // 새로운 메시지가 수신될 때마다 Snackbar를 표시
			const latestMessage = messages[messages.length - 1];
			if(latestMessage.type==='Receive'){
				setCurrentMessage(`${latestMessage.content}`);
				setOpenAlarm(true);
			}
		}
		
	}, [messages]);

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/
	function handlerClick(item) {
		// 현재 페이지 경로 가져오기

		// 메인 페이지 경로
		const mainPagePath = '/';

		// 결과 입력 페이지 경로
		const resultPagePath = '/result';

		// 설정 페이지 경로
		const settingPagePath = '/setting';

		if (item === mainPagePath) {
			// 현재 페이지가 메인 페이지 경로일 때 실행할 코드
			setTabId(1);
			navigate(item);
		} else if (item.startsWith(resultPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			if (!window.sessionStorage.getItem('workListId')) {
				disableHandler();
			} else {
				setTabId(2);
				navigate(`/result/${window.sessionStorage.getItem('workListId')}`);
			}
		} else if (item.startsWith(settingPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(3);
			navigate(item);
		}
	}

	// Snackbar 닫기 핸들러
	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	// 버튼 비활성화 시 Snackbar 표시
	const disableHandler = () => {
		setSnackbarOpen(true);
	};

	//웹소켓 스낵바 닫기ㅣ
	const handleCloseAlarm = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlarm(false);
    };

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<>
			<Box className={classes.base}>
				<Box className={classes.logoContent} component="span">
					<LogoSection />
					<Box className={classes.linkContent}>
						{map(headerList, (item, index) => (
							<Typography
								key={index}
								variant={
									item.title === '결과입력' &&
									!window.sessionStorage.getItem('workListId')
										? 'HeaderPageDisabled'
										: _tabId === item.id
										? 'HeaderPageSelect'
										: 'HeaderPage'
								}
								onClick={() => handlerClick(item.path)}
							>
								{item.title}
							</Typography>
						))}
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<ReviewSender work list={_reviewSenderList} setList={setReviewSenderList} />
					<ReviewReceiver list={_reviewReceiverList} setList={setReviewReceiverList} />
					<Profile />
				</Box>
			</Box>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
				message={
					<Typography variant="imageCardTitle">
						이전 결과입력 수검자가 없습니다.
					</Typography>
				}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				ContentProps={{
					sx: {
						backgroundColor: theme.palette.primary[400],
						padding: '1rem 2rem',
						borderRadius: '8px',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
					},
				}}
			/>
			<Snackbar
				open={openAlarm}
				autoHideDuration={2000}
				onClose={handleCloseAlarm}
				action={<CloseIcon onClick={handleCloseAlarm}/>}
				message={
					<Typography variant="imageCardTitle">
						{currentMessage} 
					</Typography>
				}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				ContentProps={{
					sx: {
						width:'30rem',
						backgroundColor: theme.palette.background.paper,
						padding: '1rem 2rem',
						borderRadius: '8px',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
					},
				}}
			/>
		</>
	);
};

export default Header;
