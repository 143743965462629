import { FormControlLabel, Checkbox as MuiCheckbox, Typography } from '@mui/material';
import { CheckboxIcon, CheckboxIconActive } from 'ui-component/icons/icons';

/**
 * 체크박스 컴포넌트
 * @param {boolean} isAble 체크버튼 disable값
 * @param {string} label 체크박스 옆 텍스트
 * @param {string} fontSize 라벨 fontSize
 * @param {number} fontWeight 라벨 fontWeight
 * @param {boolean} check check 여부
 * @param {setState} valueChange 체크 상태의 이벤트
 */
function Checkbox({
	isAble = true,
	label,
	fontSize = '0.875rem',
	fontWeight = 400,
	check = true,
	valueChange,
	...props
}) {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/
	const handleChange = (e) => {
		valueChange(e.target.checked);
	};

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<FormControlLabel
			control={
				<MuiCheckbox
					checked={check}
					onChange={handleChange}
					disabled={!isAble}
					{...props}
					sx={{ width: '2rem' }}
					icon={<CheckboxIcon />}
					checkedIcon={<CheckboxIconActive />}
				/>
			}
			label={
				<Typography
					fontSize={fontSize}
					fontWeight={fontWeight}
					whiteSpace={'nowrap'}
					variant="checkBoxValue"
				>
					{label}
				</Typography>
			}
		/>
	);
}

export default Checkbox;
