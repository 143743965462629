import { makeStyles } from '@mui/styles';
import {
	Dialog as MuiDialog,
	DialogTitle as MuiDialogTitle,
	DialogContent,
	DialogActions as MuiDialogActions,
	IconButton,
	Typography,
} from '@mui/material';
import { CloseIcon } from '../icons/icons';
import Button from 'ui-component/button/Button';

const useStyles = makeStyles((theme) => ({ ...theme.component.uiComponent.dialogs.dialogAlert }));

/**
 * 슬라이드 상에서의 셀의 위치를 나타내는 슬라이드카드
 * @param {string} title title 텍스트
 * @param {node} children children
 * @param {string} subText subText string
 * @param {boolean} hasClose 닫힘상태 boolean
 * @param {function} onClose onClose 시 발생 이벤트
 * @param {string} okText okText string
 * @param {string} cancelText cancelText string
 * @param {boolean} hasCancel 'cancelText버튼 표시 boolean',
 * @param {boolean} hasOk 'okText버튼 표시 boolean',
 * @param {string} maxWidth mui size ex_sm, sl...
 * @param {string} sx sx
 */
function Dialog({
	title = '',
	children,
	subText = '',
	hasClose = true,
	onClose,
	okText = '확인',
	cancelText = '취소',
	hasCancel = false,
	hasOk = true,
	maxWidth = 'sm',
	sx = '',
}) {
	const classes = useStyles();

	return (
		<MuiDialog open={true} maxWidth={maxWidth} sx={{ ...sx }}>
			<MuiDialogTitle>
				{title}
				{hasClose && (
					<IconButton onClick={() => onClose(false)} className={classes.icon}>
						{CloseIcon({ selected: false })}
					</IconButton>
				)}
			</MuiDialogTitle>

			<DialogContent className={classes.content}>
				{children}
				<Typography variant="subText">{subText}</Typography>
			</DialogContent>

			<MuiDialogActions className={classes.action}>
				{hasCancel && (
					<Button size="large" fullWidth onClick={() => onClose(false)}>
						{cancelText}
					</Button>
				)}
				{hasOk && (
					<Button size="large" color="primary" fullWidth onClick={() => onClose(true)}>
						{okText}
					</Button>
				)}
			</MuiDialogActions>
		</MuiDialog>
	);
}
export default Dialog;
