// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_LOGON = '@session/SET_LOGON';
export const SET_COTCHMARKWORKLIST = '@session/SET_COTCHMARKWORKLIST';
export const SET_COTCHMARKRESULT = '@session/SET_COTCHMARKRESULT';
export const SET_MEMBERINFO = '@session/SET_MEMBERINFO';
export const SET_TEAMINFO = '@session/SET_TEAMINFO';
export const SET_ALERTINFO = '@session/SET_ALERTINFO';
export const SET_APPS = '@session/SET_APPS';
export const SET_SUBSCRIPTION = '@session/SET_SUBSCRIPTION';

export const SET_SEARCH = '@search/SET_SEARCH';

export const SET_DECISION_RELIABILITY = '@setting/SET_DECISION_RELIABILITY';
export const SET_DECISION_DOUBT = '@setting/SET_DECISION_DOUBT';
export const SET_ALL_CELL_COUNT = '@setting/SET_ALL_CELL_COUNT';
export const SET_IMAGE_RELIABILITY = '@setting/SET_IMAGE_RELIABILITY';
export const SET_IMAGE_DOUBT = '@setting/SET_IMAGE_DOUBT';
export const SET_IMAGE_ALIGN = '@setting/SET_IMAGE_ALIGN';
export const SET_COACHMARKS_DISABLE_WORKLIST = '@setting/SET_COACHMARKS_DISABLE_WORKLIST';
export const SET_COACHMARKS_DISABLE_RESULT = '@setting/SET_COACHMARKS_DISABLE_RESULT';
export const SET_NRBC_COEFFICIENT = '@setting/SET_NRBC_COEFFICIENT';