// action - state management
import * as actionTypes from './actions';

export const initialState = {
  option: {
    _decisionReliability: { settingId: 0, settingValue: [0, 100] },
    _decisionDoubt: { settingId: 0, settingValue: 75 },
    _allCellCount: { settingId: 0, settingValue: 100 },
    _imageReliability: { settingId: 0, settingValue: [0, 100] },
    _imageDoubt: { settingId: 0, settingValue: 75 },
    _imageAlign: { settingId: 0, settingValue: 'DESC' },
    _coachmarksDisableWorkList: { settingId: 0, settingValue: [] },
    _coachmarksDisableResult: { settingId: 0, settingValue: [] },
  },
  coefficient: {
    _NRBCCoefficient: { settingId: 0, settingValue: 1 },
  },
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DECISION_RELIABILITY:
      return {
        ...state,
        option: {
          ...state.option,
          _decisionReliability: { ...state.option._decisionReliability, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_DECISION_DOUBT:
      return {
        ...state,
        option: {
          ...state.option,
          _decisionDoubt: { ...state.option._decisionDoubt, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_ALL_CELL_COUNT:
      return {
        ...state,
        option: {
          ...state.option,
          _allCellCount: { ...state.option._allCellCount, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_IMAGE_RELIABILITY:
      return {
        ...state,
        option: {
          ...state.option,
          _imageReliability: { ...state.option._imageReliability, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_IMAGE_DOUBT:
      return {
        ...state,
        option: {
          ...state.option,
          _imageDoubt: { ...state.option._imageDoubt, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_IMAGE_ALIGN:
      return {
        ...state,
        option: {
          ...state.option,
          _imageAlign: { ...state.option._imageAlign, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_COACHMARKS_DISABLE_WORKLIST:
      return {
        ...state,
        option: {
          ...state.option,
          _coachmarksDisableWorkList: { ...state.option._coachmarksDisableWorkList, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_COACHMARKS_DISABLE_RESULT:
      return {
        ...state,
        option: {
          ...state.option,
          _coachmarksDisableResult: { ...state.option._coachmarksDisableResult, settingId: action.settingId, settingValue: action.value },
        },
      };
    case actionTypes.SET_NRBC_COEFFICIENT:
      return {
        ...state,
        coefficient: {
          ...state.coefficient,
          _NRBCCoefficient: { ...state.coefficient._NRBCCoefficient, settingId: action.settingId, settingValue: action.value },
        },
      };
    default:
      return state;
  }
};

export default settingReducer;
