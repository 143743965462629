// action - state management
import * as actionTypes from './actions';
import { startOfToday, endOfToday, subMonths } from 'date-fns';

export const initialState = {
	searchInfo: {
		workListDecisionCode: 'N,A',
		workListStatusCode: '10,20,30,40',
		searchDateRange: {
			start: subMonths(startOfToday(), 3),
			end: endOfToday(),
		},
		searchCategory: 1,
		searchKeyword: '',
		decisionCheckboxItems: [
			{ code: 'N', value: '양호', checked: true },
			{ code: 'A', value: '주의', checked: true },
		],
		statusCheckboxItems: [
			{ code: '10', value: '대기', checked: true },
			{ code: '20', value: '입력', checked: true },
			{ code: '30', value: '재검', checked: true },
			{ code: '40', value: '완료', checked: true },
		],
		decisionRequestCheckboxItems: [
			{ code: '10', value: '담당자 이관', checked: true },
			{ code: '30', value: '재검', checked: false },
			{ code: '20', value: 'AI분석결과 이상', checked: false },
		],
	},
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_SEARCH:
			return {
				...state,
				searchInfo: action.searchInfo || { ...initialState.searchInfo },
			};
		default:
			return state;
	}
};

export default searchReducer;
