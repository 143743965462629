import { useState, useEffect, useCallback } from 'react';
import {
	Button,
	Box,
	Stack,
	Popper,
	Typography,
	Select,
	MenuItem,
	TextField,
	IconButton,
	ClickAwayListener,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import {
	CloseIcon,
	SelectIcon,
	SelectIconActive,
	IconPopup1,
	IconPopup2,
} from 'ui-component/icons/icons';
import PBSAIAPI from 'views/apis/PBSAIAPI';
import Checkbox from 'ui-component/input/Checkbox/Checkbox';
import Dialog from 'ui-component/dialogs/Dialog';
import { map, filter, includes, find } from 'lodash';
import U2CloudAPI from 'views/apis/U2CloudAPI';
import { CodeReviewRequestReason, WorkListStatusCode } from 'store/enums';
import Lodash from 'lodash';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout,
	...theme.component.uiComponent.common,
	...theme.component.layout.mainLayout.header.reviewPopup,
}));

/**
 * 검토요청 팝업
 * 결과입력의 검토요청일 시 EditSelectedOption 미사용
 * 보낸요청의 검토요청(편집)일 시 setInfoWorkList 미사용
 * @param {*} param0
 *
 */
function ReviewRequestPopup({
	open,
	anchorRef,
	onClose,
	infoWorkList,
	setInfoWorkList = null, // request only
	EditSelectedOption = null, // edit only
	placement = 'bottom-end',
	modifiers = null, // edit only
	onClick = null, // edit only
	type = 'request', // request / edit
	setReviewRequest = null, //edit only
	itemAll,
}) {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/
	const classes = useStyles();
	const theme = useTheme();
	const session = useSelector((state) => state.session);

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	const [_reasonArray, setReasonArray] = useState([]);
	const [_selectedOption, setSelectedOption] = useState({
		memo: '',
		reviewRequestReasonCode: '',
		receiverMemberId: 0,
		receiverMemberEmail: '',
		receiverMemberName: '',
		senderMemberId: session.memberInfo.memberId,
		senderMemberName: session.memberInfo.memberName,
		teamId: 1,
		workListId: infoWorkList?.workListId,
	});
	const [openAlert, setOpenAlert] = useState(false);
	const [openErrorAlert, setOpenErrorAlert] = useState(false);
	const [_consignee, setConsignee] = useState([]);
	const [_openConsignee, setOpenConsignee] = useState(false);

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/
	const callApi = useCallback(async () => {
		try {
			let members = await U2CloudAPI.GetMembers();
			const filteredMembers = members.data.filter((item) => {
				return item.memberId !== session.memberInfo.memberId;
			});
			setConsignee(filteredMembers);
		} catch (error) {
			console.error('Error while fetching and processing data:', error);
		}
	}, [setConsignee, session.memberInfo.memberId]);

	useEffect(() => {
		callApi();
	}, [callApi]);

	async function putRequest() {
		PBSAIAPI.PutReviewRequest(_selectedOption).then((res) => {
			if (res.status === 200) {
				//요청 사유가 담당자 이관일 시 워크리스트 해당 담당자로 변경
				if (
					includes(
						_selectedOption.reviewRequestReasonCode,
						CodeReviewRequestReason[0].value,
					)
				) {
					PBSAIAPI.PatchWorkListMemberChangeByWorkListId(
						_selectedOption.workListId,
						_selectedOption.receiverMemberId,
						Lodash.find(
							_consignee,
							(member) => member.memberId === _selectedOption.receiverMemberId,
						).memberName,
					).then((res) => {
						if (res.status === 200) {
							setOpenAlert(true);
							setInfoWorkList((prev) => {
								let res = {
									...prev,
									memberId: _selectedOption.receiverMemberId,
									memberName: Lodash.find(
										_consignee,
										(member) =>
											member.memberId === _selectedOption.receiverMemberId,
									).memberName,
								};
								return res;
							});
						}
					});
				}
				//요청 사유가 재검일 시 워크리스트 상태 재검으로 변경
				if (
					includes(
						_selectedOption.reviewRequestReasonCode,
						CodeReviewRequestReason[1].value,
					)
				) {
					PBSAIAPI.PatchWorkListStatusChangeByWorkListId(
						_selectedOption.workListId,
						WorkListStatusCode[4].value,
					).then((res) => {
						if (res.status === 200) {
							setOpenAlert(true);
							setInfoWorkList((prev) => {
								let res = {
									...prev,
									workListStatusCode: WorkListStatusCode[4].value,
									workListStatus: WorkListStatusCode[4].name,
								};
								return res;
							});
						}
					});
				}
			} else {
				setOpenErrorAlert(true);
			}
		});
	}

	async function patchRequest() {
		PBSAIAPI.PatchReviewRequest(EditSelectedOption.reviewRequestId, _selectedOption).then(
			(res) => {
				if (res.status === 200) {
					//요청 사유가 담당자 이관일 시 워크리스트 해당 담당자로 변경
					if (
						includes(
							_selectedOption.reviewRequestReasonCode,
							CodeReviewRequestReason[0].value,
						)
					) {
						PBSAIAPI.PatchWorkListMemberChangeByWorkListId(
							_selectedOption.workListId,
							_selectedOption.receiverMemberId,
							Lodash.find(
								_consignee,
								(member) => member.memberId === _selectedOption.receiverMemberId,
							).memberName,
						).then((res) => {
							if (res.status === 200) {
								setOpenAlert(true);
							}
						});
					}
					//요청 사유가 재검일 시 워크리스트 상태 재검으로 변경
					if (
						includes(
							_selectedOption.reviewRequestReasonCode,
							CodeReviewRequestReason[1].value,
						)
					) {
						PBSAIAPI.PatchWorkListStatusChangeByWorkListId(
							_selectedOption.workListId,
							WorkListStatusCode[4].value,
						).then((res) => {
							if (res.status === 200) {
								setOpenAlert(true);
							}
						});
					}
					//편집일 때 상위 리스트에서 변경된 객체 갱신
					if (setReviewRequest !== null) {
						setReviewRequest((prev) =>
							Lodash.map(prev, (item) => {
								if (item.reviewRequestId === res.data.reviewRequestId) {
									return { infoWorkList: item.infoWorkList, ...res.data };
								}
								return item;
							}),
						);
					}
				} else {
					setOpenErrorAlert(true);
				}
			},
		);
	}

	useEffect(() => {
		if (open && EditSelectedOption !== null) {
			setSelectedOption({
				memo: EditSelectedOption.memo,
				reviewRequestReasonCode: EditSelectedOption.reviewRequestReasonCode,
				receiverMemberId: EditSelectedOption.receiverMemberId,
				receiverMemberEmail: EditSelectedOption.receiverMemberEmail,
				receiverMemberName: EditSelectedOption.receiverMemberName,
				senderMemberId: EditSelectedOption.senderMemberId,
				senderMemberName: EditSelectedOption.senderMemberName,
				teamId: EditSelectedOption.teamId,
				workListId: EditSelectedOption.workListId,
			});
		} else {
			setSelectedOption({
				memo: '',
				reviewRequestReasonCode: '',
				receiverMemberId: 0,
				receiverMemberEmail: '',
				receiverMemberName: '',
				// reviewRequestId: '1',
				senderMemberId: session.memberInfo.memberId,
				senderMemberName: session.memberInfo.memberName,
				teamId: 1,
				workListId: infoWorkList?.workListId,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		async function getReasonArray() {
			let response = await PBSAIAPI.GetCodeReviewRequestReasonByWorkListId();
			const result = map(response.data, (item) => {
				return {
					...item,
					checked: itemAll
						? itemAll?.reviewRequestReasonCode
								.split(',')
								.includes(item.reviewRequestReasonCode)
						: false,
				};
			});
			setReasonArray(result);
		}
		getReasonArray();
	}, [itemAll]);

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/

	const handlerSelectUpdate = (e) => {
		let receiverMember = Lodash.find(_consignee, (item) => {
			return item.memberId === e.target.value;
		});
		setSelectedOption((prevOption) => ({
			...prevOption, // 현재 상태 복사
			receiverMemberId: e.target.value, // receiverMemberId 업데이트
			receiverMemberEmail: receiverMember.memberEmail,
			receiverMemberName: receiverMember.memberName,
		}));
	};

	const handlerMemoUpdate = (e) => {
		setSelectedOption((prevOption) => ({
			...prevOption, // 현재 상태 복사
			memo: e.target.value, // memo 업데이트
		}));
	};

	function handlerSelectArray(targetKey, isChecked) {
		const resultArray = _reasonArray.map((item) => {
			if (item.reviewRequestReasonCode === targetKey) {
				// 원하는 key 값을 가진 객체에 checked 속성을 추가합니다.
				return { ...item, checked: isChecked };
			}
			return item;
		});

		let checkedItems = filter(resultArray, { checked: true });
		let checkedCodeString = map(checkedItems, (item) => item.reviewRequestReasonCode).join(',');

		setReasonArray(resultArray);

		setSelectedOption((prevOption) => ({
			...prevOption, // 현재 상태 복사
			reviewRequestReasonCode: checkedCodeString, // reviewRequestReasonCode 업데이트
		}));

		const opposite = targetKey === '1' ? '2' : targetKey === '2' ? '1' : 'Invalid code';

		const resultOppositeArray = resultArray.map((item) => {
			if (item.reviewRequestReasonCode === opposite) {
				// 원하는 key 값을 가진 객체에 checked 속성을 추가합니다.
				return { ...item, checked: false };
			}
			return item;
		});

		checkedItems = filter(resultOppositeArray, { checked: true });
		checkedCodeString = map(checkedItems, (item) => item.reviewRequestReasonCode).join(',');

		setReasonArray(resultOppositeArray);

		setSelectedOption((prevOption) => ({
			...prevOption, // 현재 상태 복사
			reviewRequestReasonCode: checkedCodeString, // reviewRequestReasonCode 업데이트
		}));
	}

	function handleClose(e) {
		setOpenAlert(false);
		setOpenErrorAlert(false);
		setReasonArray((prev) => {
			return prev.map((item) => {
				return { ...item, checked: false };
			});
		});
		onClose(e);
	}

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<Popper
			placement={placement}
			modifiers={modifiers}
			onClick={onClick}
			anchorEl={anchorRef}
			open={open}
			sx={{ paddingTop: '20px', zIndex: 1000 }}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<MainCard
					border={false}
					content={false}
					sx={{
						border: `2px solid ${theme.palette.grey[550]}`,
						padding: '0rem 1.25rem',
						boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.1)',
					}}
				>
					<Stack width={'25.625rem'}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							height={'3.125rem'}
						>
							<Typography variant="resultTitleW">검토요청</Typography>
							<IconButton onClick={handleClose} sx={{ padding: '0px' }}>
								<CloseIcon selected={false} width={'24px'} height={'24px'} />
							</IconButton>
						</Stack>
						<Stack className={classes.decisionRequest}>
							<Box className={classes.alignColumnStart}>
								<Box className={classes.infoList}>
									<IconPopup1 />
									<Typography variant="infoContent">
										{infoWorkList?.specimenId?.replace(
											/(\d{6})(\d{6})/,
											'$1-$2',
										)}
									</Typography>
								</Box>
								<Box className={classes.infoList}>
									<IconPopup2 />
									<Typography variant="infoContent">
										{infoWorkList?.patientName} |{' '}
										{infoWorkList?.patientBirthday?.split(' ')[0]}{' '}
										{infoWorkList?.patientAgeType === 'D'
											? `(만 0세)`
											: `(만 ${infoWorkList?.patientAge}세) `}
										| {infoWorkList?.patientGenderCode}
									</Typography>
								</Box>
							</Box>
							<Box className={classes.listAlign}>
								<Box className={classes.textBox}>
									<Typography variant="resultTitleW">사유</Typography>
									<Box className={classes.checkBox}>
										<FormControl>
											<RadioGroup row>
												{filter(
													_reasonArray,
													(item) => item.reviewRequestReasonCode !== '3',
												).map((item) => (
													<FormControlLabel
														key={item.reviewRequestReasonCode}
														value={item.reviewRequestReason}
														control={
															<Radio
																onChange={(e) => {
																	handlerSelectArray(
																		item.reviewRequestReasonCode,
																		e.target.checked,
																	);
																}}
																checked={item.checked}
															/>
														}
														label={
															<Typography
																fontSize={'0.875rem'}
																fontWeight={400}
																whiteSpace={'nowrap'}
																variant="checkBoxValue"
															>
																{item.reviewRequestReason}
															</Typography>
														}
														// valueChange={(e) => handlerSelectArray(item.reviewRequestReasonCode, e)}
													/>
												))}

												{(() => {
													const targetItem = find(_reasonArray, {
														reviewRequestReasonCode: '3',
													});
													return (
														targetItem && (
															<Checkbox
																key={
																	targetItem.reviewRequestReasonCode
																}
																check={targetItem.checked}
																valueChange={(e) => {
																	handlerSelectArray(
																		targetItem.reviewRequestReasonCode,
																		e,
																	);
																}}
																label={
																	targetItem.reviewRequestReason
																}
															/>
														)
													);
												})()}
											</RadioGroup>
										</FormControl>
									</Box>
								</Box>

								<Box className={classes.textBox}>
									<Typography variant="resultTitleW">인수자</Typography>
									<Select
										onClick={() => setOpenConsignee(!_openConsignee)}
										open={_openConsignee}
										IconComponent={(e) => {
											if (e.className.includes('MuiSelect-iconOpen')) {
												return (
													<Box
														sx={{
															display: 'flex',
															margin: '0px 10px',
														}}
													>
														{SelectIconActive()}
													</Box>
												);
											} else {
												return (
													<Box
														sx={{
															display: 'flex',
															margin: '0px 10px',
														}}
													>
														{SelectIcon()}
													</Box>
												);
											}
										}}
										value={
											_selectedOption.receiverMemberId
												? _selectedOption.receiverMemberId
												: ''
										}
										onChange={handlerSelectUpdate}
										sx={{ width: '21.625rem', height: '2.5rem' }}
										MenuProps={{
											disablePortal: true,
											anchorReference: 'none',
											BackdropProps: {
												sx: {
													backgroundColor: 'rgba(0, 0, 0, 0)',
												},
											},
											PaperProps: {
												sx: {
													minHeight:
														_consignee.length > 4
															? `11rem`
															: `${_consignee.length * 2.75}rem`,
													maxHeight: '11rem',
													overflowY: 'auto',
												},
											},
											style: {
												top: '2.5rem',
												left: 0,
												position: 'absolute',
											},
										}}
									>
										{map(_consignee, (item, index) => (
											<MenuItem
												key={index}
												value={item.memberId}
												sx={{ width: 'auto', height: '2.5rem' }}
											>
												{item.memberName}
											</MenuItem>
										))}
									</Select>
								</Box>

								<Box className={classes.textBox}>
									<Typography variant="resultTitleW">메모</Typography>
									<TextField
										value={_selectedOption.memo}
										onChange={handlerMemoUpdate}
										rows={3}
										multiline
										sx={{
											width: '21.625rem',
											'& .MuiInputBase-root': {},
										}}
									/>
								</Box>
							</Box>
							<Box className={classes.alignBtn}>
								<Button
									onClick={type === 'request' ? putRequest : patchRequest}
									className={classes.buttonPadding}
									disabled={
										!(
											_selectedOption.receiverMemberId &&
											_selectedOption.memo &&
											_selectedOption.reviewRequestReasonCode
										)
									}
									sx={{ backgroundColor: theme.palette.primary.light }}
								>
									<Typography variant="resultTitle">
										{type === 'request' ? '요청' : '수정'}
									</Typography>
								</Button>
								{!!openAlert && (
									<Dialog onClose={handleClose}>
										{type === 'request'
											? `${
													find(
														_consignee,
														(member) =>
															member.memberId ===
															_selectedOption.receiverMemberId,
													).memberName
											  }님께
                                         [${
												infoWorkList?.patientName
											}]님 검체의 검토요청을 보냈습니다.`
											: `[${infoWorkList?.patientName}]님 검체의 검토요청이 수정되었습니다.`}
									</Dialog>
								)}
								{!!openErrorAlert && (
									<Dialog onClose={handleClose}>
										{'검토요청 전송에 오류가 발생하였습니다.'}
									</Dialog>
								)}
							</Box>
						</Stack>
					</Stack>
				</MainCard>
			</ClickAwayListener>
		</Popper>
	);
}

export default ReviewRequestPopup;
