import Axios from 'utils/AxiosUtil';

var PBSAIAPI = typeof exports === 'undefined' ? function numeric() {} : exports;
if (typeof global !== 'undefined') {
	global.PBSAIAPI = PBSAIAPI;
}

const PBSAIAPISERVICE = '/api/pbsai';

PBSAIAPI.GetWorkListSearch = function GetWorkListSearch(
	workListDecisionCode,
	workListStatusCode,
	startReceptionDate,
	endReceptionDate,
	searchCategory,
	searchKeyword,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoWorkList/search` +
			`?workListDecisionCode=${workListDecisionCode}` +
			`&workListStatusCode=${workListStatusCode}` +
			`&startReceptionDate=${startReceptionDate}` +
			`&endReceptionDate=${endReceptionDate}` +
			`&searchCategory=${searchCategory}` +
			`&searchKeyword=${searchKeyword}`,
	);
};
PBSAIAPI.GetWorkList = function GetWorkList(workListId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoWorkList/${workListId}`);
};

PBSAIAPI.PatchWorkListStatusChangeByWorkListId = function PatchWorkListStatusChangeByWorkListId(
	workListId,
	status,
	memberId,
	memberName,
) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoWorkList/${workListId}`, {
		workListStatusCode: status,
		memberId: memberId,
		memberName: memberName,
	});
};

PBSAIAPI.PatchWorkListMemberChangeByWorkListId = function PatchWorkListMemberChangeByWorkListId(
	workListId,
	memberId,
	memberName,
) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoWorkList/${workListId}`, {
		memberId: memberId,
		memberName: memberName,
	});
};

PBSAIAPI.GetReviewRequestReceiverList = function GetReviewRequestReceiverList(receiverMemberId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoReviewRequest/${receiverMemberId}/ReceiverList`);
};

PBSAIAPI.GetReviewRequestSenderList = function GetReviewRequestSenderList(senderMemberId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoReviewRequest/${senderMemberId}/SenderList`);
};

PBSAIAPI.PutReviewRequest = function PutReviewRequest(body) {
	return Axios.Put(PBSAIAPISERVICE, `/InfoReviewRequest`, body);
};

PBSAIAPI.PatchReviewRequest = function PatchReviewRequest(reviewRequestId, body) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoReviewRequest/${reviewRequestId}`, body);
};

PBSAIAPI.PatchReviewsendAllReadRequest = function PatchReviewsendAllReadRequest(body) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoReviewRequest/sendAllRead`, body);
};

PBSAIAPI.GetWorkListItem = function GetWorkListItem(workListId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoWorkList/${workListId}/InfoWorkListItem`);
};

PBSAIAPI.GetResultByWorkListItemId = function GetResultByWorkListItemId(workListItemId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoWorkListItem/${workListItemId}/InfoResult`);
};
PBSAIAPI.PatchWorkListItem = function PatchWorkListItem(workListItemId, body) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoWorkListItem/${workListItemId}`, body);
};

PBSAIAPI.GetResultByWorkListId = function GetResultByWorkListId(workListId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoResult/search?workListId=${workListId}`);
};

PBSAIAPI.PatchResultByResultId = function PatchResultByResultId(resultId, patchData) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoResult/${resultId}`, patchData);
};

PBSAIAPI.GetCellClassSearch = function GetCellClassSearch(cellClassCategory) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/LibraryCellClass/search?cellClassCategory=${cellClassCategory}`,
	);
};

PBSAIAPI.GetCellCountByCellClassId = function GetCellCountByCellClassId(workListId) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoCell/getCellCountByCellClassId?workListId=${workListId}`,
	);
};

PBSAIAPI.GetCellSearch = function GetCellSearch(
	workListId,
	cellClassCategory,
	sortType,
	limitPerCellClass,
	fromConfidence,
	toConfidence,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoCell/searchLimitPerCellClass` +
			`?workListId=${workListId}` +
			`&cellClassCategory=${cellClassCategory}` +
			`&sortBy=confidence` +
			`&sortType=${sortType}` +
			`&limitPerCellClass=${limitPerCellClass}` +
			`&fromConfidence=${fromConfidence}` +
			`&toConfidence=${toConfidence}`,
	);
};

PBSAIAPI.GetCellSearchByCellClassId = function GetCellSearchByCellClassId(
	workListId,
	cellClassId,
	sortType,
	offset,
	rowCount,
	fromConfidence,
	toConfidence,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoCell/searchByCellClassId` +
			`?workListId=${workListId}` +
			`&cellClassId=${cellClassId}` +
			`&sortBy=confidence` +
			`&sortType=${sortType}` +
			`&offset=${offset}` +
			`&rowCount=${rowCount}` +
			`&fromConfidence=${fromConfidence}` +
			`&toConfidence=${toConfidence}`,
	);
};

PBSAIAPI.SearchLimitPerRatio = function SearchLimitPerRatio(
	cellClassCategory, // 셀 클래스 분류 (RBC, WBC, PLT)
	limitPerCellCount, // 조회할 레코드 수
	sortBy = 'confidence', // 정렬 컬럼 (confidence)
	sortType, // 정렬 방식 (ASC, DESC)
	workListId, // 워크리스트 ID
	fromConfidence,
	toConfidence,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoCell/searchLimitPerRatio` +
			`?cellClassCategory=${cellClassCategory}` +
			`&limitPerCellCount=${limitPerCellCount}` +
			`&sortBy=${sortBy}` +
			`&sortType=${sortType}` +
			`&workListId=${workListId}` +
			`&fromConfidence=${fromConfidence}` +
			`&toConfidence=${toConfidence}`,
	);
};

PBSAIAPI.PatchInfoCellByCellClassId = function PatchInfoCellByCellClassId(cellId, id) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoCell/${cellId}`, {
		cellClassId: id,
	});
};

PBSAIAPI.GetInfoCellByWorkListId = function GetInfoCellByWorkListId(
	workListId,
	fromConfidence,
	toConfidence,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/InfoCell/cellCountByWorkListId?workListId=${workListId}` +
			`&fromConfidence=${fromConfidence}` +
			`&toConfidence=${toConfidence}`,
	);
};

PBSAIAPI.GetInfoCellByImageId = function GetInfoCellByImageId(imageId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoCell/searchByImageId?imageId=${imageId}`);
};

PBSAIAPI.GetLibraryOpinion = function GetLibraryOpinion(keyword) {
	return Axios.Get(PBSAIAPISERVICE, `/LibraryOpinion/search?keyword=${keyword}`);
};

PBSAIAPI.PatchLibraryOpinion = function PatchLibraryOpinion(opinionId, opinionCode, opinion) {
	return Axios.Patch(PBSAIAPISERVICE, `/LibraryOpinion/${opinionId}`, {
		opinionCode: opinionCode,
		opinion: opinion,
	});
};

PBSAIAPI.PutLibraryOpinion = function PutLibraryOpinion(
	opinionCategory,
	opinionCode,
	opinion,
	teamId,
) {
	return Axios.Put(PBSAIAPISERVICE, `/LibraryOpinion`, {
		opinionCategory: opinionCategory,
		opinionCode: opinionCode,
		opinion: opinion,
		teamId: teamId,
	});
};

PBSAIAPI.DeleteLibraryOpinion = function DeleteLibraryOpinion(opinionId) {
	return Axios.Delete(PBSAIAPISERVICE, `/LibraryOpinion/${opinionId}`);
};

PBSAIAPI.GetLibraryTestAndDecision = function GetLibraryTestAndDecision() {
	return Axios.Get(PBSAIAPISERVICE, `/LibraryTest/teamId`);
};

PBSAIAPI.PostTestDecision = function PostTestDecision(testDecisionObject) {
	return Axios.Post(PBSAIAPISERVICE, `/LibraryTest/saveAll`, testDecisionObject);
};

PBSAIAPI.GetLibraryTestReference = function GetLibraryTestReference(
	age,
	ageType,
	gender,
	testClassCode,
) {
	return Axios.Get(
		PBSAIAPISERVICE,
		`/LibraryTestReference/search?` +
			`age=${age}` +
			`&ageType=${ageType}` +
			`&gender=${gender}` +
			`&testClassCode=${testClassCode}`,
	);
};

PBSAIAPI.GetImageByWorkListId = function GetImageByWorkListId(workListId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoWorkList/${workListId}/InfoImage`);
};

PBSAIAPI.GetImageBySearch = function GetImageBySearch(
	imageType,
	workListId,
	isIdentified = null, // WBC 탐지 여부
	rowCount = 0, // 가져올 개수, 0 입력 시 전체
	offset = 0, // 가져올 위치(정렬 시), 0 입력 시 전체
	sortBy = 'imageId', // 기본값, 필요 시 정렬 기준 컬럼 입력
	sortType = 'ASC', // 기본값, 필요 시 정렬 방식 입력
) {
	if (isIdentified === null) {
		return Axios.Get(
			PBSAIAPISERVICE,
			`/InfoImage/search?imageType=${imageType}&offset=${offset}&rowCount=${rowCount}&sortBy=${sortBy}&sortType=${sortType}&workListId=${workListId}`,
		);
	} else {
		return Axios.Get(
			PBSAIAPISERVICE,
			`/InfoImage/search?imageType=${imageType}&isIdentified=${isIdentified}&offset=${offset}&rowCount=${rowCount}&sortBy=${sortBy}&sortType=${sortType}&workListId=${workListId}`,
		);
	}
};

PBSAIAPI.GetOpinionByWorkListId = function GetOpinionByWorkListId(workListId) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoWorkList/${workListId}/InfoOpinion`);
};

PBSAIAPI.PutOpinionByOpinionId = function PutOpinionByOpinionId(
	opinionId,
	workListId,
	teamId,
	text,
) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoOpinion`, {
		opinionId: opinionId,
		opinion: text,
		teamId: teamId,
		workListId: workListId,
	});
};

PBSAIAPI.PatchOpinionByOpinionId = function PatchOpinionByOpinionId(opinionId, text) {
	return Axios.Patch(PBSAIAPISERVICE, `/InfoOpinion/${opinionId}`, {
		opinion: text,
	});
};

PBSAIAPI.GetCodeReviewRequestReasonByWorkListId =
	function GetCodeReviewRequestReasonByWorkListId() {
		return Axios.Get(PBSAIAPISERVICE, `/CodeReviewRequestReason/search`);
	};

PBSAIAPI.GetInfoSettingByCatgory1 = function GetInfoSettingByCatgory1(category1) {
	return Axios.Get(PBSAIAPISERVICE, `/InfoSetting/search?category1=${category1}`);
};

PBSAIAPI.PutInfoSettingByCatgory1 = function PutInfoSettingByCatgory1(settingId, settingValue) {
	return Axios.Put(PBSAIAPISERVICE, `/InfoSetting/update`, {
		settingId: settingId,
		settingValue: settingValue,
	});
};

PBSAIAPI.ResetWorklist = function ResetWorklist(password, workListId) {
	return Axios.Put(PBSAIAPISERVICE, `/InfoSetting/reset/worklist`, {
		password: password,
		workListId: workListId,
	});
};

export default PBSAIAPI;
