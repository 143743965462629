export default function themeTypography(theme) {
	const fontFamily = `'S-Core Dream', 'Roboto'`;
	const body2 = {
		letterSpacing: '0em',
		lineHeight: '1.0em',
		color: theme.darkTextPrimary,
	};

	return {
		// 사용할 스타일 정의
		logoU2: {
			fontSize: '1.7rem',
			color: theme.colors?.primaryMain,
			fontWeight: 700,
			fontFamily: 'Roboto',
		},
		logoName: {
			fontSize: '1.7rem',
			fontWeight: 700,
			fontFamily: fontFamily,
		},
		CBCpageTitle: {
			fontSize: '0.875rem',
			fontWeight: 500,
			fontFamily: fontFamily,
			color: theme.colors?.grey900,
		},
		HeaderPageSelect: {
			fontSize: '1.125rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			cursor: 'pointer',
			color: theme.colors?.primaryMain,
		},
		HeaderPageDisabled: {
			fontSize: '1.125rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			cursor: 'pointer',
			color: '#BCBCBC',
		},
		HeaderPage: {
			fontSize: '1.125rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			cursor: 'pointer',
			color: theme.colors?.grey900,
		},
		pageTitle: {
			fontSize: '0.875rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			cursor: 'pointer',
		},
		body2Bold: {
			...body2,
			fontSize: '1.0rem',
			fontWeight: 500,
			fontFamily: fontFamily,
		},
		checkBoxTitle: { fontSize: '0.875rem', fontWeight: 500, fontFamily: fontFamily },
		checkBoxValue: { fontSize: '0.875rem', fontWeight: 400, fontFamily: fontFamily },
		imageCardTitle: {
			...body2,
			fontSize: '1.0rem',
			fontWeight: 500,
			fontFamily: fontFamily,
			color: theme.colors?.black200,
			width: '14.375rem',
		},

		// MUI 컴포넌트에서 기본적으로 사용되는 폰트
		body2: body2,

		// 워크리스트 상세정보 타이틀
		infoTitle: {
			...body2,
			lineHeight: '1.5rem',
			fontSize: '0.875rem',
			fontWeight: 400,
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
		},

		// 워크리스트 상세정보 내용
		infoContent: {
			...body2,
			lineHeight: '1.5rem',
			fontSize: '0.875rem',
			fontWeight: 500,
			color: theme.colors?.grey900,
			fontFamily: fontFamily,
			// width: '20rem',
			// overflow: 'hidden' /* 글자가 넘칠 경우 숨김 처리 */,
			whiteSpace: 'nowrap' /* 텍스트 줄바꿈 금지 */,
		},

		// 접수시간이나 팝업의 하위텍스트 등 단독으로 사용되지 않는 작은 글씨
		subText: {
			...body2,
			fontSize: '0.8125rem',
			fontWeight: 400,
			color: theme.colors?.grey900,
			fontFamily: fontFamily,
		},

		// 결과검토카드 타이틀
		resultTitle: {
			...body2,
			fontSize: '0.875rem',
			// lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 700,
		},

		// 결과검토카드 타이틀
		opinionTitle: {
			...body2,
			fontSize: '0.875rem',
			// lineHeight: '2rem',
			color: theme.colors?.grey750,
			fontFamily: fontFamily,
			fontWeight: 700,
		},

		// 결과검토카드 타이틀
		decisionResultTitle: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
		},

		resultSubTitle: {
			...body2,
			fontSize: '0.8125rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
		},

		resultSubTitleBlack: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey900,
			fontFamily: fontFamily,
			fontWeight: 500,
		},

		// 검토요청 헤더
		resultTitleW: {
			...body2,
			fontSize: '0.875rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			color: theme.colors?.grey900,
			width: '4rem',
		},

		// 결과검토카드 셀이름
		dataGrid: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey900,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
		},

		// 결과검토카드 셀이름
		dataGridComplete: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey350,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
		},

		// 결과검토카드 셀이름
		resultContent: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
		},

		// 결과검토카드 셀이름
		resultContentLookCenter: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
			paddingRight: '11px',
		},

		// 결과검토카드 셀이름
		resultContentLookCenterDash: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
			paddingLeft: '32.5px',
			paddingRight: '32.5px',
		},

		// 결과검토카드 셀이름
		resultContentAbnormal: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.errorMain,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1.8rem',
			height: '1.8rem',
		},

		// 결과검토카드 셀이름
		resultContentError: {
			...body2,
			fontSize: '0.8125rem',
			color: '#BBBBBB',
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '1rem',
			height: '1rem',
			borderRadius: '0.6rem',
			padding: '0 0.3rem',
		},

		// 결과검토카드 셀이름(상태)
		resultContentStatus: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 400,
		},

		opinionLibraryLeft: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
			width: '30%',
			textAlign: 'left',
		},
		opinionLibraryRight: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
			width: '70%',
			textAlign: 'left',
		},
		opinionLibraryMiddle: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
			width: '60%',
			textAlign: 'left',
		},
		opinionLibraryEnd: {
			...body2,
			fontSize: '0.875rem',
			lineHeight: '2rem',
			color: theme.colors?.grey0,
			fontFamily: fontFamily,
			fontWeight: 500,
			width: '10%',
			textAlign: 'left',
		},
		opinionLibraryNoResult: {
			width: '100%',
			whiteSpace: 'normal',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 400,
			textAlign: 'center',
		},
		statusComplete: {
			color: theme.colors?.successMain,
			fontFamily: fontFamily,
			fontWeight: 700,
		},

		profileName: { color: theme.colors?.black, fontWeight: 500, fontFamily: fontFamily },
		profileEmail: {
			fontSize: '0.9rem',
			fontWeight: 400,
			marginTop: '0.2rem',
			fontFamily: fontFamily,
		},
		button: {
			fontSize: '0.875rem',
			fontFamily: fontFamily,
			fontWeight: 700,
		},
		decisionAbnormal: {
			color: theme.colors?.errorMain,
			fontFamily: fontFamily,
		},
		differentValue: { backgroundColor: theme.colors?.warning100, fontFamily: fontFamily },

		decisionText: {
			fontSize: '0.8125rem',
			fontWeight: 700,
			fontFamily: fontFamily,
		},

		tabButton: { fontSize: '0.875rem', fontWeight: 700, fontFamily: fontFamily },
		tabButtonSub: {
			fontSize: '0.625rem',
			fontWeight: 700,
			fontFamily: fontFamily,
			display: 'flex',
			gap: '4px',
			justifyContent: 'center',
		},
		NRBCNumber: {
			fontSize: '0.9375rem',
			fontFamily: fontFamily,
			fontWeight: 700,
			color: theme.colors?.grey900,
		},

		imageCardInfo: {
			fontSize: '0.8125rem',
			fontFamily: fontFamily,
			fontWeight: 400,
			color: theme.colors?.grey900,
		},

		opinionLibraryTitle: {
			fontSize: '0.9375rem',
			fontFamily: fontFamily,
			fontWeight: 700,
			color: theme.colors?.grey700,
		},

		requestTitle: {
			fontSize: '0.9rem',
			fontWeight: 700,
			color: theme.colors?.grey900,
		},
		decisionResultBody1: {
			...body2,
			fontSize: '0.75rem',
			lineHeight: '2rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 500,
		},
		decisionResultBody2: {
			...body2,
			fontSize: '0.8125rem',
			lineHeight: '2rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 500,
		},
		// 설정 헤더
		settingHeader: {
			...body2,
			fontSize: '0.8125rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 600,
			lineHeight: '1.8rem',
			height: '1.8rem',
		},
		// 설정 설명부
		settingDiscription: {
			...body2,
			fontSize: '0.75rem',
			color: theme.colors?.grey500,
			fontFamily: fontFamily,
			fontWeight: 400,
			lineHeight: '0.75rem',
			height: '0.75rem',
		},
		// 설정 회사정보 헤더
		settingCompanyHeader: {
			...body2,
			fontSize: '0.75rem',
			color: theme.colors?.grey700,
			fontFamily: fontFamily,
			fontWeight: 700,
			lineHeight: '0.75rem',
			height: '0.75rem',
		},

		// // 이 하위의 스타일은 현재 미사용
		// // 큰 제목
		// h6: {
		// 	fontSize: '1rem',
		// 	fontWeight: 700,
		// 	color: theme.heading,
		// },
		// 메뉴
		// h5: {
		// 	fontSize: '1.4rem',
		// 	color: theme.heading,
		// 	fontWeight: 700,
		// 	fontFamily: fontFamily,
		// },
		// // 섹션 내부 제목
		// h4: {
		// 	fontSize: 'resultTitlerem',
		// 	color: theme.heading,
		// 	fontWeight: 700,
		// },
		// // 미사용
		// h3: {
		// 	fontSize: '2.0rem',
		// 	color: theme.heading,
		// 	fontWeight: 700,
		// },
		// // 페이지 제목
		// h2: {
		// 	fontSize: '2.5rem',
		// 	color: theme.heading,
		// 	fontWeight: 700,
		// },
		// // 미사용
		// h1: {
		// 	color: theme.heading,
		// 	fontWeight: 700,
		// 	fontSize: '3rem',
		// },
		// // 소제목
		// subtitle1: {
		// 	fontSize: '1rem',
		// 	fontWeight: 700,
		// 	color: theme.textDark,
		// },
		// // 소제목 하위
		// subtitle2: {
		// 	fontSize: '0.95rem',
		// 	fontWeight: 500,
		// 	color: theme.darkText,
		// },
		// caption: {
		// 	fontSize: '0.9rem',
		// 	fontWeight: 500,
		// },
		// // 안내문구
		// notice: {
		// 	fontSize: '1rem',
		// 	fontWeight: 500,
		// 	color: theme.colors?.grey700,
		// },
		// info: {
		// 	fontSize: '1rem',
		// 	color: theme.darkText,
		// 	'& > span': {
		// 		color: theme.colors?.primaryMain,
		// 	},
		// },
		// label: {
		// 	fontSize: '1rem',
		// 	fontWeight: 500,
		// 	color: 'inherit',
		// },
		// body1: {
		// 	fontSize: '1.0rem',
		// 	fontWeight: 100,
		// 	lineHeight: '1.334em',
		// },

		// customInput: {
		// 	marginTop: 1,
		// 	marginBottom: 1,
		// 	'& > label': {
		// 		top: 23,
		// 		left: 0,
		// 		color: theme.grey500,
		// 		'&[data-shrink="false"]': {
		// 			top: 5,
		// 		},
		// 	},
		// 	'& > div > input': {
		// 		padding: '30.5px 14px 11.5px !important',
		// 	},
		// 	'& legend': {
		// 		display: 'none',
		// 	},
		// 	'& fieldset': {
		// 		top: 0,
		// 	},
		// },
		// menuCaption: {
		// 	fontSize: '1rem',
		// 	fontWeight: 500,
		// 	color: theme.heading,
		// 	padding: '1rem',
		// 	textTransform: 'capitalize',
		// 	marginTop: '1rem',
		// },
		// subMenuCaption: {
		// 	fontSize: '1rem',
		// 	fontWeight: 500,
		// 	color: theme.darkTextSecondary,
		// 	textTransform: 'capitalize',
		// },
	};
}
