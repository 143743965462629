// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import Routes from './routes';
import themes from './themes';
import NavigationScroll from './layout/NavigationScroll'
// import CookieUtil from './utils/CookieUtil';
// import { ACCESSTOKEN } from 'store/constant';
// import Test from 'Test';

// ==============================|| APP ||============================== //

const App = () => {
	const customization = useSelector((state) => state.customization);
	// useEffect(() => {
	// 	return () => {
	// 		CookieUtil.fctRemoveCookie(ACCESSTOKEN);
	// 	};
	// }, []);

		// ContentProps={{
		// 	sx: {
		// 		backgroundColor: theme.palette.primary[400],
		// 		padding: '1rem 2rem',
		// 		borderRadius: '8px',
		// 		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
		// 	},
		// }}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes(customization)}>
				<CssBaseline />
				<NavigationScroll>
					<Routes />
				</NavigationScroll>
			</ThemeProvider>
		</StyledEngineProvider>
		// <Test></Test>
	);
};

export default App;
