import { useCallback, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
	Box,
	Button as MuiButton,
	ClickAwayListener,
	Popper,
	Stack,
	Typography,
	Divider,
	IconButton,
	Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // X 아이콘 추가

import MainCard from '../../../../ui-component/cards/MainCard';
import {
	EditIconActive,
	IconHospital,
	IconPopup1,
	IconPopup2,
	OpinionLibraryIcon,
	SendRequestIcon,
} from 'ui-component/icons/icons';
import DecisionTag from 'ui-component/icons/DecisionTag';
import PBSAIAPI from 'views/apis/PBSAIAPI';
import { CodeReviewRequestReason } from 'store/enums';
import ReviewRequestPopup from 'ui-component/popup/ReviewRequestPopup';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout.header.reviewSender,
}));

export default function ReviewSender(props) {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/

	const theme = useTheme();
	const classes = useStyles();

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	const [open, setOpen] = useState(false);
	const [_openDecisionRequest, setOpenDecisionRequestIndex] = useState([]);
	const anchorRef = useRef(null);
	const popupAnchorRefs = useRef([]);

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/

	const handlerClickRequestPage = useCallback((workListId) => {
		window.open(`/result/${workListId}`, '_self');
		setOpen(false);
	}, []);

	const handlerClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
		setOpenDecisionRequestIndex(null);
	};

	const handlerToggle = useCallback(() => {
		setOpen((prevOpen) => !prevOpen);
	}, []);

	// X 버튼 클릭 핸들러 함수
	const handleClickRemoveButton = (id) => {
		PBSAIAPI.PatchReviewRequest(id, { isSenderCheck: true }).then((res) => {
			if (res.status === 200) {
				props.setList((prevList) => prevList.filter((item) => item.reviewRequestId !== id));
			}
		});
	};

	// 모두삭제 버튼 클릭 핸들러 함수
	const handleClickRemoveAllButton = () => {
		PBSAIAPI.PatchReviewsendAllReadRequest().then((res) => {
			if (res.status === 200) {
				if (res.status === 200) {
					props.setList([]);
				}
			}
		});
	};

	function handleClickEditPopup(e, index) {
		setOpenDecisionRequestIndex(index);
	}
	function handleCloseEditPopup() {
		setOpenDecisionRequestIndex(null);
	}

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	// 사유 출력 함수
	function getNamesFromString(str) {
		return str
			.split(',')
			.map(Number)
			.map((value) => {
				const match = CodeReviewRequestReason.find((item) => item.value === String(value));
				return match ? (
					<Typography
						key={value}
						variant="decisionResultBody2"
						className={classes.reason}
					>
						# {match.name}
					</Typography>
				) : null;
			})
			.filter((name) => name !== null); // 매칭되지 않는 값은 필터링
	}

	return (
		<>
			<MuiButton
				variant="text"
				onClick={handlerToggle}
				ref={anchorRef}
				disableRipple
				startIcon={<SendRequestIcon />}
				sx={{ '&:hover': { backgroundColor: 'transparent' } }}
			>
				<Typography variant="requestTitle" pr={1}>
					보낸 요청
				</Typography>
				<Typography color={theme.palette.error.main}>{props.list.length}</Typography>
			</MuiButton>
			<Popper
				placement="bottom-start"
				open={open}
				anchorEl={anchorRef.current}
				modifiers={[
					{
						name: 'offset',
						options: {
							offset: [-70, 0], // [horizontal, vertical]
						},
					},
					{
						name: 'preventOverflow',
						enabled: false, // preventOverflow 비활성화하여 팝업이 고정된 위치에 있도록 설정
					},
					{
						name: 'flip',
						enabled: false, // flip 비활성화하여 팝업이 위치를 자동으로 바꾸지 않도록 설정
					},
				]}
				sx={{
					paddingTop: '14px',
					width: '28rem',
				}}
			>
				<ClickAwayListener onClickAway={handlerClose}>
					<MainCard
						border={true}
						elevation={16}
						content={false}
						boxShadow
						hoverBoxShadow
						className={classes.card}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width={'100%'}
							height={'5rem'}
							sx={{ padding: '0 1.5rem' }}
						>
							<Box sx={{ width: '50%', padding: '0', height: '4rem' }}>
								<Box
									sx={{
										width: '100%',
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography variant="requestTitle">보낸 요청</Typography>
								</Box>
								<Box
									className={classes.count}
									sx={{
										width: '100%',
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography color={theme.palette.error.main}>
										{props.list.length}
									</Typography>
									개
								</Box>
							</Box>
							<Box sx={{ width: '50%', padding: '0', height: '4rem' }}>
								<Box
									sx={{
										width: '100%',
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<IconButton onClick={handlerClose} sx={{ padding: '0' }}>
										<CloseIcon
											selected={false}
											width={'20px'}
											height={'20px'}
										/>
									</IconButton>
								</Box>
								<Box
									sx={{
										width: '100%',
										height: '2rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<Button
										sx={{
											display: 'flex',
											gap: '0.5rem',
											width: '5rem',
											height: '2rem',
											padding: '5px 4px',
											borderRadius: 4,
											backgroundColor: theme.palette.secondary.main,
											'&:active': {
												backgroundColor: theme.palette.secondary.main,
											},
											'&:hover': {
												backgroundColor: theme.palette.secondary.main,
											},
										}}
										onClick={() => handleClickRemoveAllButton()}
									>
										<Typography variant="resultTitle">모두 삭제</Typography>
									</Button>
								</Box>
							</Box>
						</Stack>

						<Stack className={classes.itemArea}>
							{!props.list || props.list.length === 0 ? (
								<Box sx={{ padding: 2, textAlign: 'center' }}>
									<Typography variant="body1" color="textSecondary">
										현재 리뷰 요청 항목이 없습니다.
									</Typography>
								</Box>
							) : (
								props.list.map((item, index) => (
									<Box
										key={index}
										onClick={() => handlerClickRequestPage(item.workListId)}
										className={classes.item}
									>
										<Stack
											direction="row"
											spacing={2}
											alignItems="center"
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<Typography
												variant="decisionResultBody1"
												sx={{
													display: 'flex',
													alignItems: 'center',
													fontWeight: 'bold',
													gap: '0.3rem',
												}}
											>
												<IconPopup1 />
												{item.infoWorkList.specimenId.replace(
													/(\d{6})(\d{6})/,
													'$1-$2',
												)}
											</Typography>

											<Typography variant="decisionResultBody1">
												{item.infoWorkList.workListStatus}
											</Typography>
											<DecisionTag
												status={item.infoWorkList.workListDecisionCode}
											/>

											<Typography variant="decisionResultBody1">
												{item.creationDate}
											</Typography>

											{/* X 버튼 */}
											<IconButton
												size="small"
												onClick={(event) => {
													event.stopPropagation(); // 상위 이벤트 전파 중단
													handleClickRemoveButton(item.reviewRequestId);
												}}
												sx={{ width: '4px', height: '4px' }}
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										</Stack>
										<Divider sx={{ marginY: 1 }} />
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											sx={{
												marginTop: 1.25,
												marginBottom: 0.5,
												gap: '1rem',
												justifyContent: 'flex-start',
											}}
										>
											<Typography
												variant="decisionResultBody2"
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3rem',
												}}
											>
												<IconPopup2 />
												{item.infoWorkList.patientName} (만{' '}
												{item.infoWorkList.patientAge}세) |{' '}
												{item.infoWorkList.patientGenderCode}
											</Typography>

											<Typography
												variant="decisionResultBody2"
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3rem',
												}}
											>
												<IconHospital /> {item.infoWorkList.compName}
											</Typography>
										</Stack>

										<Typography
											variant="decisionResultBody2"
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '0.3rem',
											}}
										>
											<OpinionLibraryIcon />
											{item.memo}
										</Typography>

										<Stack direction={'row'}>
											{getNamesFromString(item.reviewRequestReasonCode)}
										</Stack>

										<Stack direction={'row'}>
											<Button
												// disabled={isloading}
												onClick={(event) => {
													event.stopPropagation();
													handleClickEditPopup(event, index);
												}}
												// isSelect={_isAllCellView}
												// className={classes.allCellViewBtn}
												ref={(el) => (popupAnchorRefs.current[index] = el)}
												sx={{
													width: '5rem',
													padding: '5px 4px',
													borderRadius: 4,
													backgroundColor: theme.palette.secondary.main,
													'&:active': {
														backgroundColor:
															theme.palette.secondary.main,
													},
													'&:hover': {
														backgroundColor:
															theme.palette.secondary.main,
													},
												}}
											>
												<EditIconActive />
												<Typography variant="resultTitle">편집</Typography>
											</Button>
											<Typography
												variant="caption"
												color="textSecondary"
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'flex-end',
													gap: '0.3rem',
													marginTop: 2,
													width: '100%',
												}}
											>
												To. {item.receiverMemberName}
											</Typography>
										</Stack>
										<ReviewRequestPopup
											open={_openDecisionRequest === index}
											anchorRef={popupAnchorRefs.current[index]}
											onClose={handleCloseEditPopup}
											infoWorkList={item.infoWorkList}
											EditSelectedOption={item}
											setReviewRequest={props.setList}
											placement={'left'}
											modifiers={[
												{
													name: 'offset',
													options: {
														offset: [0, 40], // [horizontal, vertical]
													},
												},
											]}
											onClick={(e) => {
												e.stopPropagation();
											}}
											type={'edit'}
											itemAll={item}
										/>
									</Box>
								))
							)}
						</Stack>
					</MainCard>
				</ClickAwayListener>
			</Popper>
		</>
	);
}
