import { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";

export const useWebSocket = (memberId) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // memberId가 유효하지 않으면 WebSocket 연결을 시도하지 않음
        if (!memberId) {
            // console.warn("memberId가 유효하지 않으므로 WebSocket 연결을 하지 않습니다.");
            return;
        }

        // WebSocket 초기화
        const socket = new SockJS(`${process.env.REACT_APP_API_PBSAI}/ws`);
        const client = Stomp.over(() => socket);
        
        
        client.debug = () => {};

        client.connect({}, () => {
            // 특정 사용자 경로를 구독하여 수신할 준비를 합니다.
            client.subscribe(`/user/${memberId}/queue/private`, (message) => {
                if (message.body) {
                    setMessages((prevMessages) => [...prevMessages, JSON.parse(message.body)]);
                }
            });
        }, (error) => {
            console.error("WebSocket 연결에 실패했습니다:", error);
        });

        // 컴포넌트가 언마운트될 때 연결 해제
        return () => {
            if (client) {
                client.disconnect();
            }
        };
    }, [memberId]);

    return { messages };
};
