import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { WorkListDecision } from 'store/enums';
import { WorkListDecisionTag } from 'store/enums';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	...theme.component.uiComponent.decisionTag,
}));

/**
 * 입력 값(N, A)
 * @param {string} status status에 "N" or "A"값이 들어가야한다
 */
function DecisionTag({ status }) {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/

	const classes = useStyles();

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/
	const fctDecisionColor = useCallback(() => {
		switch (status) {
			case WorkListDecision[0].value: // 양호
				return classes.tagNormal;
			case WorkListDecision[1].value: // 주의
				return classes.tagAbnormal;
			case WorkListDecision[2].value: // 의심
				return classes.tagWarning;
			default:
				return classes.tagError;
		}
	}, [status, classes.tagNormal, classes.tagAbnormal, classes.tagError, classes.tagWarning]);

	const fctDecisionContent = useCallback(() => {
		switch (status) {
			case WorkListDecision[0].value: // 양호
				return WorkListDecisionTag[0].value;
			case WorkListDecision[1].value: // 주의
				return WorkListDecisionTag[1].value;
			case WorkListDecision[2].value: // 의심
				return WorkListDecisionTag[2].value;
			default: //결과없음
				return '';
		}
	}, [status]);

	return <Box className={fctDecisionColor(status)}>{fctDecisionContent(status)}</Box>;
}

export default DecisionTag;
