import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Header from './Header';
import * as actionTypes from  '../../store/actions';
import { CloseIcon, InfoCircleIcon } from 'ui-component/icons/icons';
import CookieUtil from 'utils/CookieUtil';
import { ACCESSTOKEN } from 'store/constant';
import U2CloudAPI from 'views/apis/U2CloudAPI';
import EncryptUtil from 'utils/EncryptUtil';
import { makeStyles } from '@mui/styles';
import PBSAIAPI from 'views/apis/PBSAIAPI';
import { SettingEnum } from 'store/enums';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout,
	...theme.component.uiComponent.common,
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
	const classes = useStyles();
	const isLogOn = useSelector((state) => state.session.isLogOn);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isInit, setIsInit] = useState(true);
	const alertInfo = useSelector((state) => state.session.alertInfo);
	const handleAlertClose = () => {
		dispatch({ type: actionTypes.SET_ALERTINFO, value: { ...alertInfo, open: false } });
	};

	/****************************************************************************************************
	 *
	 * 로그인 체크
	 *
	 ****************************************************************************************************/
	useEffect(() => {
		async function fctLoad() {
			if (!!CookieUtil.fctGetCookie(ACCESSTOKEN)) {
				let res = await U2CloudAPI.GetMyInfo();
				if (isInit) {
					let setting_res = await PBSAIAPI.GetInfoSettingByCatgory1('스크리너');

					setting_res.data.forEach((foundSetting) => {
						let parsedValue;
						try {
							parsedValue = JSON.parse(foundSetting.settingValue);
						} catch (e) {
							parsedValue = foundSetting.settingValue;
						}
						// String / Int 등 타입 고려
						let settingValue;
						if (Array.isArray(parsedValue)) {
							settingValue = parsedValue;
						} else if (!isNaN(parsedValue)) {
							settingValue = Number(parsedValue);
						} else {
							settingValue = parsedValue;
						}

						const settingKey = Object.keys(SettingEnum).find(
							(key) => SettingEnum[key].settingName.toLowerCase() === foundSetting.settingName.toLowerCase()
						);
						
						if (settingKey) {
							const { type, settingName } = SettingEnum[settingKey];
							dispatch({
								type: type,
								key: settingName,
								settingId: foundSetting.settingId,
								value: settingValue,
							});
						}
						if (res.status === 200) {
							if (foundSetting.settingName === SettingEnum['_coachmarksDisableWorkList'].settingName) {
								if (!settingValue.includes(res.data.memberId)) {
									dispatch({ type: actionTypes.SET_COTCHMARKWORKLIST, success: true });
								}
							}
							if (foundSetting.settingName === SettingEnum['_coachmarksDisableResult'].settingName) {
								if (!settingValue.includes(res.data.memberId)) {
									dispatch({ type: actionTypes.SET_COTCHMARKRESULT, success: true });
								}
							
							}
						}
						
					});
					setIsInit(false);
				}

				if (res.status === 200) {
					dispatch({ type: actionTypes.SET_LOGON, success: true });
					dispatch({ type: actionTypes.SET_MEMBERINFO, value: res.data });
				} else {
					CookieUtil.fctRemoveCookie(ACCESSTOKEN);

					window.open(
						`${
							process.env.REACT_APP_PORTAL
						}/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`,
						'_self',
					);
				}
			} else {
				window.open(
					`${process.env.REACT_APP_PORTAL}/login?redirect=${EncryptUtil.fctEncodeToHex(
						window.location.href,
					)}`,
					'_self',
				);
			}
		}

		fctLoad();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogOn, navigate, dispatch]);



	return (
		<Box>
			<Box className={classes.base}>
				<Header />
				<Box className={classes.contents}>
					<Stack className={`${classes.contentBox}`} spacing={1} direction={'row'}>
						<Outlet />
					</Stack>
				</Box>
			</Box>
			<Snackbar
				key={alertInfo?.key}
				open={alertInfo?.open || false}
				message={
					<Box className={classes.centerAlignRow}>
						{alertInfo?.icon ? (
							alertInfo?.type === 'toast' ? (
								<CheckCircleOutlineIcon />
							) : alertInfo?.type === 'alert' ? (
								<InfoCircleIcon />
							) : (
								''
							)
						) : (
							''
						)}
						<Typography pl={3}>{alertInfo?.message || ''}</Typography>
					</Box>
				}
				autoHideDuration={
					alertInfo?.type === 'toast'
						? 3000
						: alertInfo?.type === 'alert'
						? undefined
						: 10000
				}
				transitionDuration={100}
				onClose={handleAlertClose}
				anchorOrigin={
					alertInfo?.type === 'toast'
						? { vertical: 'bottom', horizontal: 'right' }
						: alertInfo?.type === 'alert'
						? { vertical: 'top', horizontal: 'center' }
						: { vertical: 'top', horizontal: 'center' }
				}
				ContentProps={{
					sx: {
						background: 'white',
						color: 'black',
					},
				}}
				action={
					alertInfo?.type === 'alert' && (
						<IconButton sx={{ p: 1 }} onClick={handleAlertClose}>
							<CloseIcon width="1rem" height="1rem" />
						</IconButton>
					)
				}
			/>
		</Box>
	);
};

export default MainLayout;
