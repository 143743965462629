// action - state management
import * as actionTypes from './actions';

const initialState = {
	isLogOn: false,
	isCoachMarkWorkList: false,
	isCoachMarkResult: false,
	memberInfo: {
		cellNumber: '',
		creationDate: '',
		isMarketingConsent: 0,
		joinDate: '',
		lastEditDate: '',
		memberEmail: '',
		memberId: 0,
		memberName: '',
		memberStatusId: 0,
		memberStatusText: '',
		roleId: '',
		roleText: '',
		statusDetail: null,
		teamId: 0,
		teamName: '',
		verifySendDate: '',
	},
	// teamInfo: {
	// 	teamId: null,
	// 	teamName: '',
	// },
	settingInfo: {},
	codes: {},
	alertInfo: { key: null, open: false, icon: false, type: '', message: '' },
	subscriptions: [],
	apps: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LOGON:
			return {
				...state,
				isLogOn: action.success,
			};

		case actionTypes.SET_MEMBERINFO:
			return {
				...state,
				memberInfo: action.value || { ...initialState.memberInfo },
			};

		case actionTypes.SET_COTCHMARKWORKLIST:
			return {
				...state,
				isCoachMarkWorkList: action.success,
			};
		
		case actionTypes.SET_COTCHMARKRESULT:
			return {
				...state,
				isCoachMarkResult: action.success,
			};
		// case actionTypes.SET_TEAMINFO:
		// 	return {
		// 		...state,
		// 		teamInfo: action.value || { ...initialState.teamInfo },
		// 	};

		case actionTypes.SET_ALERTINFO:
			return { ...state, alertInfo: action.value };

		case actionTypes.SET_APPS:
			return {
				...state,
				apps: action.value || [],
			};

		case actionTypes.SET_SUBSCRIPTION: {
			return { ...state, subscriptions: action.value };
		}

		default:
			return state;
	}
};

export default sessionReducer;
