import * as actionTypes from  './actions';


export const WorkListDecision = [
	{ value: 'N', content: '양호' }, // 양호
	{ value: 'A', content: '주의' }, // 주의
	{ value: 'R', content: '의심' }, // 의심
	{ value: 'B', content: '결과없음' }, // 결과없음
	{ value: 'U', content: '식별불가' }, // 식별불가
	{ value: 'I', content: '이상' }, // 이상, 사용자 입력
];

Object.freeze(WorkListDecision);

export const WorkListDecisionTag = [
	{ value: '양호' }, // 양호
	{ value: '주의' }, // 주의
	{ value: '의심' }, // 의심
	{ value: '' }, // 결과없음
];

Object.freeze(WorkListDecisionTag);

export const WorkListStatusCode = [
	{ value: '01', name: '전송' }, // 전송
	{ value: '02', name: '전송오류' }, // 전송오류
	{ value: '10', name: '대기' }, // 대기
	{ value: '20', name: '입력' }, // 입력
	{ value: '30', name: '재검' }, // 재검
	{ value: '40', name: '완료' }, // 완료
];

Object.freeze(WorkListStatusCode);

export const CodeReviewRequestReason = [
	{ value: '1', name: '담당자 이관' }, // 전송
	{ value: '2', name: '재검' }, // 전송오류
	{ value: '3', name: 'AI분석결과 이상' }, // 대기
];

Object.freeze(CodeReviewRequestReason);

export const PairCBC = [
	{ testName: 'Segmented Neutrophil', testCodeAI: 'PW1008', testCodeCBC: 'PW1017' },
	{ testName: 'Lymphocyte', testCodeAI: 'PW1009', testCodeCBC: 'PW1018' },
	{ testName: 'Monocyte', testCodeAI: 'PW1010', testCodeCBC: 'PW1019' },
	{ testName: 'Eosinophil', testCodeAI: 'PW1011', testCodeCBC: 'PW1020' },
	{ testName: 'Basophil', testCodeAI: 'PW1012', testCodeCBC: 'PW1021' },
];

Object.freeze(PairCBC);

export const SettingEnum = {
	_decisionReliability: { type: actionTypes.SET_DECISION_RELIABILITY, settingName: '_decisionReliability' },
	_decisionDoubt: { type: actionTypes.SET_DECISION_DOUBT, settingName: '_decisionDoubt' },
	_allCellCount: { type: actionTypes.SET_ALL_CELL_COUNT, settingName: '_allCellCount' },
	_imageReliability: { type: actionTypes.SET_IMAGE_RELIABILITY, settingName: '_imageReliability' },
	_imageDoubt: { type: actionTypes.SET_IMAGE_DOUBT, settingName: '_imageDoubt' },
	_imageAlign: { type: actionTypes.SET_IMAGE_ALIGN, settingName: '_imageAlign' },
	_coachmarksDisableWorkList: { type: actionTypes.SET_COACHMARKS_DISABLE_WORKLIST, settingName: '_coachmarksDisableWorkList' },
	_coachmarksDisableResult: { type: actionTypes.SET_COACHMARKS_DISABLE_RESULT, settingName: '_coachmarksDisableResult' },
	_NRBCCoefficient: {type: actionTypes.SET_NRBC_COEFFICIENT, settingName: '_NRBCCoefficient'}
};

Object.freeze(SettingEnum);